import dayjs from "dayjs"
import React from "react"

export const objectString = (data, convert) => {
  if (!convert) {
    let newdata = []
    for (const key in data) {
      if (typeof data[key] === "object") {
        for (const kd in data[key]) {
          if (typeof data[key][kd] === "object") {
            for (const kdd in data[key][kd]) {
              newdata.push(
                encodeURIComponent(key) +
                  "[" +
                  encodeURIComponent(kd) +
                  "]" +
                  "[" +
                  encodeURIComponent(kdd) +
                  "]=" +
                  encodeURIComponent(data[key][kd][kdd])
              )
            }
          } else {
            newdata.push(
              encodeURIComponent(key) +
                "[" +
                encodeURIComponent(kd) +
                "]=" +
                encodeURIComponent(data[key][kd])
            )
          }
        }
      } else {
        newdata.push(encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      }
    }
    return "?" + newdata.join("&")
  } else {
    if (data) {
      data = data.replace("?", "")
      if (data) {
        data = data.split("&")
        let newdata = []
        for (const key in data) {
          let dk = data[key].split("=")
          let dkk = []
          dkk[decodeURIComponent(dk[0])] = decodeURIComponent(dk[1])
          newdata.push(dkk)
        }

        return newdata
      } else {
        return false
      }
    }
  }
}

export const toCapitalize = (str) => {
  if (!str) {
    return "-"
  } else if (typeof str !== "string") {
    return "Not Valid"
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getRoundUp = (estimated_land_area) => {
  if (!estimated_land_area) {
    return "-"
  } else {
    return Math.round(estimated_land_area)
  }
}

export const toMeter = (value) => {
  let result = toThousandConvert(value)
  return (
    <>
      {result}
      <span className={"text-lowercase"}>&nbsp;m</span>
      <sup>2</sup>
    </>
  )
}

export const toHectare = (value) => {
  let result = convertDotToComma(value)

  return (
    <>
      {result}
      <span>&nbsp;Ha</span>
    </>
  )
}

export const convertDigits = (value, digit) => {
  return parseFloat(value).toFixed(digit)
}

export const toThousandConvert = (value) => {
  let result = value
  if (typeof value === "number") {
    const main = convertDigits(value, 2)
    result = new Intl.NumberFormat("id-ID").format(main)
  } else {
    const data = parseFloat(value)
    const main = convertDigits(data, 2)
    result = new Intl.NumberFormat("id-ID").format(main)
  }
  return result
}

export const toRupiah = (value) => {
  if (typeof value === "number") {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0
    }).format(value)
  } else {
    return "-"
  }
}

export function toLocaleString(num) {
  if (num) {
    return num.toLocaleString("id-ID")
  } else if (num === 0) {
    return "0"
  } else {
    return "0"
  }
}

export function thousandSeparator(num) {
  return toLocaleString(num ?? 0)
}

export const bytesToMegaBytes = (value, type) => {
  const main = value / 1024 ** 2
  const int = main.toFixed(2)
  const result = int
  if (type === true) {
    return result + " Mb"
  } else {
    return result
  }
}

export const bytesToKiloBytes = (value, type) => {
  const main = value / 1024
  const int = main.toFixed(2)
  const result = int
  if (type === true) {
    return result + " Kb"
  } else {
    return result
  }
}

export const fileTypeSplit = (name) => {
  const file = name.split(".").reverse()[0]
  if (file === "pdf") {
    return "application/pdf"
  } else if (file === "png") {
    return "image/png"
  } else if (file === "jpg") {
    return "image/jpg"
  } else if (file === "jpeg") {
    return "image/jpeg"
  } else {
    return "Not Valid"
  }
}

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(" ")
}

export const handleSpace = (e) => {
  if (e.currentTarget.value === "" || e.target.selectionStart === 0) {
    e.keyCode === 32 && e.preventDefault()
  }
}

export const dateFormat = (date, format) => {
  return dayjs(date).format(format)
}

export const convertM2ToHectare = (num, fix) => {
  return (num / 10000).toFixed(fix || 2)
}

export const checkValue = (value, suffix) => {
  if (value === 0) return 0
  else if (value === null) return "-"
  else if (value === "") return "-"
  else if (value === undefined) return "-"
  else return value + (suffix ? " " + suffix : "")
}

export function parseThousandSeparatorToNumber(num) {
  return parseInt(num.replace(/\./g, ""))
}

export const checkCommaInDataPic = (index, length) => {
  if (length > 1 && index === 0) {
    return true
  } else if (length === 1) {
    return false
  } else if (length > 2) {
    return true
  } else if (length === 2 && index === 1) {
    return false
  }
}

export const convertDotToComma = (value) => {
  return value ? value.toString().replace(".", ",") : "-"
}

export const toKilogram = (value) => {
  let result = toThousandConvert(value)
  return (
    <>
      {result}
      <span>&nbsp;Kg</span>
    </>
  )
}

export const isEmptyObj = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

export const findFirst = (array) => {
  return array.find(Boolean)
}

export const checkDateRangeValue = (start_date, end_date) => {
  if (start_date && end_date) {
    return start_date + " s/d " + end_date
  } else {
    return "-"
  }
}
