import { Check } from "@material-ui/icons"
import React from "react"

export const Stepper = ({ step, activeStep, setActiveStep }) => {
  return (
    <div className={"stepper d-flex justify-content-between align-items-center"}>
      {step.map((item, index) => {
        return (
          <div className={`d-flex align-items-center`} key={index}>
            <div
              onClick={() => setActiveStep(index)}
              className={`step cursor-pointer mr-4 ${activeStep === index ? "active" : ""}`}>
              {activeStep > index ? <Check /> : index + 1}
            </div>
            <div className={"text"}>{item}</div>
            {index !== step.length - 1 ? <div className={"line"}></div> : ""}
          </div>
        )
      })}
    </div>
  )
}
