export const GENERAL = {
  SET_LOADING: "LOADING",
  UPLOAD: "UPLOAD",
  IS_ERROR: "IS_ERROR",
  IS_FINISH_UPDATE_OR_DELETE: "IS_FINISH_UPDATE_OR_DELETE",
  ERROR_MSG: "ERROR_MSG",
  SYNC_ERP: "SYNC_ERP",
  UPLOAD_MULTIPLE: "UPLOAD_MULTIPLE"
}

export const MASTER = {
  BASE: "MASTER",
  GET_PROVINCE: "GET_PROVINCE" + "MASTER",
  GET_REGENCY: "GET_REGENCY" + "MASTER",
  GET_DISTRICT: "GET_DISTRICT_MASTER",
  GET_SUB_DISTRICT: "GET_SUB_DISTRICT" + "MASTER",
  GET_BANKS: "GET_BANKS" + "MASTER",
  GET_STATUS_ACCOUNTS: "GET_STATUS_ACCOUNTS" + "MASTER",
  GET_PLANTS: "GET_PLANTS",
  GET_VARIETY_PLANTS: "GET_VARIETY_PLANTS",
  GET_ALL_PRIVILEGE: "GET_ALL_PRIVILEGE",
  GET_VIDEO_CATEGORIES: "GET_VIDEO_CATEGORIES",
  GET_PLANT_TYPE: "GET_PLANT_TYPE",
  GET_PLANT_FORM: "GET_PLANT_FORM",
  GET_OWNER_LIST: "GET_OWNER_LIST",
  GET_CATEGORY_ITEM: "GET_CATEGORY_ITEM",
  GET_LIST_PAGE: "GET_LIST_PAGE",
  GET_DETAIL_TREATMENT: "GET_DETAIL_TREATMENT",
  GET_VARIANT_BY_PLANT_TYPE: "GET_VARIANT_BY_PLANT_TYPE",
  GET_DETAIL_PACKAGE: "GET_DETAIL_PACKAGE",
  GET_TREATMENT_DETAIL_PACKAGE: "GET_TREATMENT_DETAIL_PACKAGE",
  GET_TREATMENT_BY_PLANT_TYPE: "GET_TREATMENT_BY_PLANT_TYPE",
  GET_MACHINE_TYPE: "GET_MACHINE_TYPE",
  GET_ACTIVITY_TYPE: "GET_ACTIVITY_TYPE",
  GET_LIST_VARIANT_BY_AREA: "GET_LIST_VARIANT_BY_AREA",
  GET_STATUS_ENUM_ACTIVITY_STATUS: "GET_STATUS_ENUM_ACTIVITY_STATUS",
  GET_REASON_FAILED_HARVEST: "GET_REASON_FAILED_HARVEST",
  GET_PEST_TYPE_LIST: "GET_PEST_TYPE_LIST",
  GET_LEGEND_MAP: "GET_LEGEND_MAP",
  GET_LIST_PLANT_TYPE_PAGEABLE: "GET_LIST_PLANT_TYPE_PAGEABLE",
  GET_LIST_REGIONAL_MASTER_DATA: "GET_LIST_REGIONAL_MASTER_DATA",
  GET_AVAILABLE_PROVINCE: "GET_AVAILABLE_PROVINCE",
  ADD_REGIONAL_DATA: "ADD_REGIONAL_DATA",
  GET_LIST_PROVINCE_EDIT_REGIONAL: "GET_LIST_PROVINCE_EDIT_REGIONAL",
  EDIT_REGIONAL_MASTER_DATA: "EDIT_REGIONAL_MASTER_DATA",
  CREATE_SUB_REGIONAL: "CREATE_SUB_REGIONAL"
}

export const MASTER_DATA = {
  CREATE_CATEGORY_ITEM: "CREATE_CATEGORY_ITEM",
  CREATE_TREATMENT: "CREATE_TREATMENT",
  SYNC_ERP_PESTICIDE: "SYNC_ERP_PESTICIDE",
  UPDATE_TREATMENT: "UPDATE_TREATMENT",
  CREATE_PACKAGE: "CREATE_PACKAGE",
  UPDATE_PACKAGE: "UPDATE_PACKAGE",
  UPDATE_STATUS_PACKAGE: "UPDATE_STATUS_PACKAGE",
  CREATE_REASON: "CREATE_REASON",
  UPDATE_REASON: "UPDATE_REASON",
  DELETE_REASON: "DELETE_REASON",
  GET_USER_MERCHANT_FILTER: "GET_USER_MERCHANT_FILTER",
  GET_PLANT_TYPE_USER: "GET_PLANT_TYPE_USER",
  CREATE_PLANT_TYPE: "CREATE_NEW_PLANT_TYPE",
  EDIT_PLANT_TYPE: "EDIT_PLANT_TYPE",
  DELETE_SUB_REGIONAL: "DELETE_SUB_REGIONAL",
  GET_LIST_REGIONAL_SELECTED: "GET_LIST_REGIONAL_SELECTED",
  GET_LIST_REGIONAL_ALL: "GET_LIST_REGIONAL_ALL",
  GET_DETAIL_REGIONAL: "GET_DETAIL_REGIONAL",
  CREATE_SUB_REGIONAL: "CREATE_SUB_REGIONAL"
}

// Field Officer
export const FIELD_OFFICER = {
  BASE: "_FIELD_OFFICER",
  ASSIGN_FO: "ASSIGN_FO_FIELD_OFFICER",
  ASSIGN_FO_BULK: "ASSIGN_FO_FIELD_OFFICER_BULK",
  GET_PAGE: "GET_PAGE_FIELD_OFFICER",
  GET_ALL_FOR_COMBOBOX: "GET_ALL_FOR_COMBOBOX_FIELD_OFFICER",
  GET_LIST: "GET_LIST",
  GET_CALENDAR_DETAIL: "GET_CALENDAR_DETAIL",
  UPDATE: "UPDATE_FIELD_OFFICER",
  DELETE: "DELETE_FIELD_OFFICER",
  GET_DETAIL: "GET_DETAIL_FIELD_OFFICER",
  GET_LIST_BY_MITRA: "GET_LIST_BY_MITRA",
  GET_ASSIGN_TAB: "GET_ASSIGN_TAB",
  GET_LIST_ASSIGN_MITRA: "GET_LIST_ASSIGN_MITRA",
  DELETE_LIST_ASSIGN_MITRA: "DELETE_LIST_ASSIGN_MITRA",
  CHECK_DEACTIVATE: "CHECK_DEACTIVATE",
  DEACTIVATE_FIELD_OFFICER: "DEACTIVATE_FIELD_OFFICER",
  GET_ALL_ROLE_FO: "GET_ALL_ROLE_FO",
  UPDATE_IMAGE: "UPDATE_IMAGE",
  GET_LIST_AVAILABLE_MEMBER: "GET_LIST_AVAILABLE_MEMBER",
  CREATE_UPDATE_TEAM: "CREATE_UPDATE_TEAM",
  GET_DETAIL_HEADER_TEAM_DRONE: "GET_DETAIL_HEADER_TEAM_DRONE",
  GET_DETAIL_TEAM_DRONE_CALENDAR: "GET_DETAIL_TEAM_DRONE_CALENDAR",
  GET_TEAM_SCHEDULE: "GET_TEAM_SCHEDULE",
  GET_DETAIL_TEAM_DRONE_CALENDAR_LIST: "GET_DETAIL_TEAM_DRONE_CALENDAR_LIST",
  GET_LIST_FO_HARVEST: "GET_LIST_FO_HARVEST",
  GET_LIST_FO_COORDINATOR: "GET_LIST_FO_COORDINATOR,",
  GET_LIST_FO_QUALITY_ASSURANCE: "GET_LIST_FO_QUALITY_ASSURANCE"
}
// MITRA
export const MITRA = {
  BASE: "_MITRA",
  GET_DETAIL: "GET_DETAIL_MITRA",
  GET_LIST_PAGE: "GET_PAGE_MITRA",
  GET_LIST_PENDING_PAGE: "GET_LIST_PENDING_PAGE",
  GET_LIST_REJECTED_PAGE: "GET_LIST_REJECTED_PAGE",
  GET_ALL_FOR_COMBOBOX: "GET_ALL_FOR_COMBOBOX_MITRA",
  GET_LIST_BY_FO: "GET_LIST_BY_FO",
  CREATE: "CREATE_MITRA",
  UPDATE: "UPDATE_MITRA",
  DELETE: "DELETE_MITRA",
  CHECK_DEACTIVATE: "CHECK_DEACTIVATE",
  DEACTIVATE_MITRA: "DEACTIVATE_MITRA",
  GET_TYPE: "GET_TYPE",
  UPDATE_IMAGE: "UPDATE_IMAGE",
  GET_LIST_MITRA_NON_KUR: "GET_LIST_MITRA_NON_KUR",
  GET_LIST_MITRA_KUR: "GET_LIST_MITRA_KUR",
  GET_LIST_MITRA: "GET_LIST_MITRA"
}

// FARMER
export const FARMER = {
  BASE: "_FARMER",
  GET_DETAIL: "GET_DETAIL_FARMER",
  GET_LIST: "GET_LIST_FARMER",
  GET_LIST_PAGE: "GET_PAGE_FARMER",
  GET_ALL_FOR_COMBOBOX: "GET_ALL_FOR_COMBOBOX_FARMER",
  UPDATE: "UPDATE_FARMER",
  DELETE: "DELETE_FARMER",
  CREATE: "CREATE_FARMER",
  CREATE_BULK: "CREATE_FARMER_BULK",
  GET_REJECT_REASON: "GET_REJECT_REASON",
  UPDATE_STATUS: "UPDATE_STATUS",
  GET_AREAS: "GET_AREAS",
  GET_DOWNLOAD: "GET_DOWNLOAD",
  CREATE_CALON: "CREATE_CALON_FARMER",
  CHECK_DEACTIVATE: "CHECK_DEACTIVATE",
  DEACTIVATE_FARMER: "DEACTIVATE_FARMER",
  CHANGE_MITRA: "CHANGE_MITRA",
  UPDATE_IMAGE: "UPDATE_IMAGE",
  GET_LIST_ACTIVITY: "GET_LIST_ACTIVITY"
}

// AREA
export const AREA = {
  BASE: "_AREA",
  GET_DETAIL: "GET_DETAIL_AREA",
  GET_MAP_DETAIL: "GET_DETAIL_MAP_AREA",
  GET_LIST_PAGE: "GET_PAGE_AREA",
  GET_ALL_FOR_COMBOBOX: "GET_ALL_FOR_COMBOBOX_AREA",
  UPDATE: "UPDATE_AREA",
  DELETE: "DELETE_AREA",
  CREATE: "CREATE_AREA",
  UPDATE_DATE_PLANT_AREA: "UPDATE_DATE_PLANT_AREA",
  GET_DETAIL_PLANTING_EVENT_AREA: "GET_DETAIL_PLANTING_EVENT_AREA",
  GET_LIST_PENDING: "GET_LIST_PENDING",
  GET_LIST_PENDING_UNASSIGN: "GET_LIST_PENDING_UNASSIGN",
  GET_LIST_REJECT: "GET_LIST_REJECT",
  GET_LIST_REJECT_REASON: "GET_LIST_REJECT_REASON",
  UPDATE_STATUS: "UPDATE_STATUS",
  GET_LIST_UNASSIGN: "GET_LIST_UNASSIGN",
  UPDATE_STATUS_FARMER: "UPDATE_STATUS_FARMER",
  UPDATE_IMAGE: "UPDATE_IMAGE",
  GET_LIST_ACTIVITY: "GET_LIST_ACTIVITY",
  PLANTING_SEASON_CONFIRMATION: "PLANTING_SEASON_CONFIRMATION",
  GET_DETAIL_ACTIVITY: "GET_DETAIL_ACTIVITY",
  GET_HISTORY_ACTIVITY: "GET_HISTORY_ACTIVITY",
  GET_HISTORY_PLANTING_SEASON: "GET_HISTORY_PLANTING_SEASON",
  GET_ACTIVITY_LAND: "GET_ACTIVITY_LAND",
  ACTIVITY_PLANTING: "ACTIVITY_PLANTING",
  ACTIVITY_SEEDLING: "ACTIVITY_SEEDLING",
  ACTIVITY_HARVEST: "ACTIVITY_HARVEST",
  ACTIVITY_TILLAGE: "ACTIVITY_TILLAGE",
  ACTIVITY_FERTILIZATION: "ACTIVITY_FERTILIZATION",
  ACTIVITY_SPRAYING: "ACTIVITY_SPRAYING",
  ACTIVITY_HARVEST_ESTIMATED: "ACTIVITY_HARVEST_ESTIMATED",
  UPDATE_ACTIVITY_IMAGE: "UPDATE_ACTIVITY_IMAGE",
  APPROVE_ACTIVITY_TO_ERP: "APPROVE_ACTIVITY_TO_ERP",
  APPROVE_ACTIVITY_TO_ERP_BULK: "APPROVE_ACTIVITY_TO_ERP_BULK",
  GET_LIST_ACTIVITY_NON_KUR: "GET_LIST_ACTIVITY_NON_KUR",
  GET_LIST_ACTIVITY_KUR: "GET_LIST_ACTIVITY_KUR",
  GET_LIST_SEEDING_BA_REPORT: "GET_LIST_SEEDING_BA_REPORT",
  CREATE_FAILED_HARVEST: "CREATE_FAILED_HARVEST",
  CANCEL_PLANTING_SEASON: "CANCEL_PLANTING_SEASON",
  CREATE_SO: "CREATE_SO",
  GET_PLANT_TYPE: "GET_PLANT_TYPE",
  GET_READY_PLANTING_SEASON_CONFIRMATION: "GET_READY_PLANTING_SEASON_CONFIRMATION",
  BULK_CONFIRMATION_PLANTING_SEASON: "BULK_CONFIRMATION_PLANTING_SEASON",
  GET_DETAIL_LAND_CLEARING: "GET_DETAIL_LAND_CLEARING"
}

// AGENDA
export const AGENDA = {
  BASE: "_AGENDA",
  GET_DETAIL: "GET_DETAIL_AGENDA",
  GET_LIST_PAGE: "GET_PAGE_AGENDA",
  GET_ALL_FOR_COMBOBOX: "GET_ALL_FOR_COMBOBOX_AGENDA",
  UPDATE: "UPDATE_AGENDA",
  DELETE: "DELETE_AGENDA",
  CREATE: "CREATE_AGENDA"
}

// RIWAYAT
export const RIWAYAT = {
  BASE: "_RIWAYAT",
  GET_DETAIL: "GET_DETAIL_RIWAYAT",
  GET_LIST_PAGE: "GET_PAGE_RIWAYAT",
  GET_ALL_FOR_COMBOBOX: "GET_ALL_FOR_COMBOBOX_RIWAYAT",
  UPDATE: "UPDATE_RIWAYAT",
  DELETE: "DELETE_RIWAYAT",
  CREATE: "CREATE_RIWAYAT"
}

// ROLE
export const ROLE = {
  LIST: "GET_LIST",
  LIST_FO: "GET_LIST_FO",
  GET_PRIVILEGE: "GET_PRIVILEGE",
  ASSIGN_PRIVILEGE: "ASSIGN_PRIVILEGE"
}

//ACCOUNT
export const ACCOUNT = {
  GET_LIST_PAGE: "GET_LIST_PAGE",
  RESET_PASSWORD: "RESET_PASSWORD",
  EDIT_PASSWORD: "EDIT_PASSWORD",
  CREATE_USER: "CREATE_USER",
  GET_DETAIL: "GET_DETAIL",
  EDIT_USER: "EDIT_USER",
  DEACTIVATE_ACCOUNT: "DEACTIVATE_ACCOUNT"
}

//VIDEO
export const VIDEO = {
  GET_LIST_PAGE: "GET_PAGE_VIDEO",
  CREATE: "CREATE",
  GET_DETAIL: "GET_DETAIL",
  UPDATE: "UPDATE",
  DELETE: "DELETE"
}

// BROADCAST MESSAGE
export const BROADCAST = {
  GET_LIST_BROADCAST: "GET_LIST_BROADCAST",
  GET_DETAIL_BROADCAST: "GET_DETAIL_BROADCAST",
  DELETE_BROADCAST: "DELETE_BROADCAST",
  GET_APP_LIST: "GET_APP_LIST",
  CREATE_BROADCAST: "CREATE_BROADCAST",
  UPLOAD_MULTIPLE_BROADCAST: "UPLOAD_MULTIPLE_BROADCAST"
}

//Upload
export const UPLOAD = {
  AVATAR: "AVATAR",
  KTP: "KTP",
  SWAFOTO: "SWAFOTO",
  BUSINESS_DOCUMENT: "BUSINESS_DOCUMENT",
  OTHER_IMAGE: "OTHER_IMAGE"
}

//STATUS LAHAN
export const STATUS_AREA = {
  ACTIVE: "ACTIVE",
  REGISTERED: "REGISTERED",
  PENDING: "PENDING",
  REVIEW: "REVIEW",
  UNASSIGNED_REVIEW: "UNASSIGNED_REVIEW",
  ASSIGN_REVIEW: "ASSIGN_REVIEW",
  REJECTED: "REJECTED",
  UNASSIGNED: "UNASSIGNED",
  LEAD_AREA: "LEAD_AREA"
}

//activity type
export const AREA_ACTIVITY_TYPE = {
  SEEDLING: "SEEDLING",
  PLANTING: "PLANTING",
  FERTILIZATION: "FERTILIZATION",
  TILLAGE: "TILLAGE",
  SPRAYING: "SPRAYING",
  HARVEST_PREDICTION: "HARVEST_PREDICTION",
  HARVEST: "HARVEST",
  DRAFT_HARVEST: "DRAFT_HARVEST",
  ON_VISIT: "ON_VISIT",
  PRE_PLANTING: "SPRAYING_PREPLANTING"
}

//potensi
export const WARNING_AREA = {
  TIDAK_ADA: "TIDAK ADA",
  KEKERINGAN: "KEKERINGAN",
  BANJIR: "BANJIR"
}

//HISTORY
export const ENUM_LOG = {
  AREA: "AREA",
  FARMER: "FARMER",
  STAFF: "STAFF",
  MITRA: "MITRA"
}

//role FO
export const ROLE_FO = {
  FIELD_OFFICER_COORDINATOR: "FIELD_OFFICER_COORDINATOR",
  FIELD_OFFICER_AGRONOMIST: "FIELD_OFFICER_AGRONOMIST",
  FIELD_OFFICER: "FIELD_OFFICER"
}

export const ASIDE_MENU = {
  FARMER: "FARMER",
  MITRA: "MITRA",
  FO: "FO",
  AREA: "AREA",
  SETTING: "SETTING",
  MASTER: "MASTER",
  ORDER: "ORDER",
  MAC: "MAC",
  SUBMISSION: "SUBMISSION"
}

export const ORDER = {
  REJECT_ORDER: "REJECT_ORDER",
  CANCEL_ORDER: "CANCEL_ORDER",
  APPROVE_ORDER: "APPROVE_ORDER",
  GET_LIST_ALL: "GET_LIST_ALL",
  GET_LIST_PASCA_PLANTING: "GET_LIST_PASCA_PLANTING",
  GET_LIST_PRE_PLANTING: "GET_LIST_PRE_PLANTING",
  GET_LIST_APPROVED: "GET_LIST_APPROVED",
  GET_LIST_PENDING: "GET_LIST_PENDING",
  GET_LIST_RESCHEDULED: "GET_LIST_RESCHEDULED",
  GET_STATUS: "GET_STATUS",
  GET_DETAIL_ORDER: "GET_DETAIL_ORDER",
  GET_DETAIL_HEADER: "GET_DETAIL_HEADER",
  GET_REGION: "GET_REGION",
  GET_DRONE_TEAM: "GET_DRONE_TEAM",
  GET_DRONE_ACTIVITY_DATE: "GET_DRONE_ACTIVITY_DATE",
  GET_CALENDAR_STATUS: "GET_CALENDAR_STATUS",
  GET_LIST_INIT_FORM_APPROVE_BULK: "GET_LIST_INIT_FORM_APPROVE_BULK",
  APPROVE_BULK_ORDER: "APPROVE_BULK_ORDER",
  GET_DETAIL_SPK: "GET_DETAIL_SPK",
  GET_DETAIL_SO: "GET_DETAIL_SO",
  GET_LIST_SO: "GET_LIST_SO",
  UPLOAD_SO_XLS: "UPLOAD_SO_XLS",
  DOWNLOAD_SO_XLS: "DOWNLOAD_SO_XLS",
  GET_SUMMARY_CARD_SPRAYING: "GET_SUMMARY_CARD_SPRAYING"
}

export const MAC = {
  GET_LIST_MERCHANT: "GET_LIST_MERCHANT",
  GET_DETAIL_PROFILE: "GET_DETAIL_PROFILE",
  GET_LIST_USER: "GET_LIST_USER",
  GET_DETAIL_USER: "GET_DETAIL_USER",
  GET_SUMMARY_CARD_SPRAYING: "GET_SUMMARY_CARD_SPRAYING",
  GET_LIST_SCHEDULING_MAC: "GET_LIST_SCHEDULING_MAC",
  GET_ACTIVITY_REPORT_MAC: "GET_ACTIVITY_REPORT_MAC",
  GET_DETAIL_SPRAYING: "GET_DETAIL_SPRAYING",
  GET_DETAIL_ACTIVITY_REPORT_MAC: "GET_DETAIL_ACTIVITY_REPORT_MAC",
  APPROVE_SPRAYING: "APPROVE_SPRAYING",
  EDIT_SPRAYING: "EDIT_SPRAYING",
  RESCHEDULE_SPRAYING: "RESHCEDULE_SPRAYING",
  REJECT_SPRAYING: "REJECT_SPRAYING",
  CANCEL_SPRAYING: "CANCEL_SPRAYING"
}

export const SUBMISSION = {
  GET_LIST_MITRA: "GET_LIST_MITRA",
  GET_LIST_SUBDISTRICT: "GET_LIST_SUBDISTRICT",
  GET_LIST_VARIETAS: "GET_LIST_VARIETAS",
  GET_LIST_CERTIFICATION: "GET_LIST_CERTIFICATION",
  GET_LIST_AREA: "GET_LIST_AREA",
  GET_LIST_AREA_MAP: "GET_LIST_AREA_MAP",
  GET_INIT_CREATE_CERTIFICATION: "GET_INIT_CREATE_CERTIFICATION",
  GET_SUMMARY_DATA: "GET_SUMMARY_DATA",
  GET_DETAIL_CERTIFICATION: "GET_DETAIL_CERTIFICATION",
  GET_LIST_SEED_CLASS: "GET_LIST_SEED_CLASS",
  CREATE_SUBMISSION: "CREATE_SUBMISSION",
  CANCEL_SUBMISSION: "CANCEL_SUBMISSION",
  GET_DETAIL_CERTIFICATION_BLOCK: "GET_DETAIL_CERTIFICATION_BLOCK",
  GET_DETAIL_DOWNLOAD_SUBMISSION: "GET_DETAIL_DOWNLOAD_SUBMISSION"
}

export const REPORT_ACTIVITY = {
  GET_LIST_AREA_BA_LAND_CLEARING: "GET_LIST_AREA_BA_LAND_CLEARING",
  GET_LIST_MITRA: "GET_LIST_MITRA",
  CREATE_BA_LAND_CLEARING: "CREATE_BA_LAND_CLEARING",
  GET_LIST_REASON_REPORT_BA: "GET_LIST_REASON_REPORT_BA",
  GET_LIST_PEST: "GET_LIST_PEST",
  DOWNLOAD_CSV: "DOWNLOAD_CSV"
}
