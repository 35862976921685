import React from "react"
import { checkValue, convertM2ToHectare } from "../../../../../helpers/TextHelper"
import { ICON_CONSTANS } from "../../../../../../constants/ImageConstants"
import { useTranslation } from "react-i18next"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers"

const OverviewArea = ({ dataInit }) => {
  const { t } = useTranslation()

  const dataArea = (
    <div className={"d-flex"}>
      {checkValue(dataInit?.area?.length)} lahan{" "}
      <span className={"font-weight-normal ml-1"}>
        <div className={"d-flex align-items-center"}>
          {`(${convertM2ToHectare(checkValue(dataInit?.total_land_area))} Ha)`}
        </div>
      </span>
    </div>
  )

  const TextLabel = ({ icon, title, description }) => {
    return (
      <>
        <div className={"d-flex align-items-center flex-lg-fill mr-5 mb-2"}>
          <span className="mr-4 rounded-circle p-3" style={{ backgroundColor: "#F4F4F4" }}>
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
          <div className="d-flex flex-column text-dark-75">
            <span className="color-gray">{title}</span>
            <span style={{ fontWeight: 500 }}>{description}</span>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={"d-flex align-items-center bg-white rounded py-4 px-8 mb-4"}>
      <TextLabel
        icon={ICON_CONSTANS.IC_MITRA_2}
        title={t("label.mitra")}
        description={dataInit?.mitra_name}
      />
      <TextLabel
        icon={ICON_CONSTANS.IC_SUB_REGIONAL}
        title={t("label.number_selected_area")}
        description={dataArea}
      />
    </div>
  )
}

export default React.memo(OverviewArea)
