import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { useSubheader } from "../../../../../_metronic/layout/index.js"
import { Card, CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { AreaList } from "./component/AreaList.jsx"
import { FlatIconAndText } from "../../../../../component/atoms/FlaticonAndText/index.jsx"
import { Status } from "../../../../../component/atoms/index.jsx"
import {
  getHistoryPlantingSeason,
  getHistoryPlantingSeasonHeader
} from "../../../../../redux/actions/AreaAction.jsx"
import { activityMonth, timeStampToDate } from "../../../../helpers/DateHelper.js"
import { checkValue, toRupiah, toThousandConvert } from "../../../../helpers/TextHelper.js"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { Skeleton } from "@material-ui/lab"
import { PRIVILEGE_ENUM } from "../../../../../constants/PrivilegeConstants.js"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { useTranslation } from "react-i18next"
import Tooltip from "@material-ui/core/Tooltip"
import { DATE_TYPE_ENUM, PLANT_TYPE_STRING } from "../../../../../constants/StringConstant.js"

export const AreaHistoryActivity = ({
  history,
  match: {
    params: { status, area_id, id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { General, Area } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const privileges = loadUserLogin().privilege
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getHistoryPlantingSeasonHeader(id))
  }, [])

  useEffect(() => {
    dispatch(getHistoryPlantingSeason(id, { pageReq: null }))
  }, [])

  useEffect(() => {
    if (Area?.data) {
      setDetail(Area?.data)
    }
  }, [Area])

  useEffect(() => {
    if (detail) {
      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: "Lahan"
        },
        {
          pathname:
            status === "active"
              ? RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all")
              : "/lahan/list/" + status,
          title: status === "active" ? "teregistrasi" : status
        },
        {
          pathname: "/lahan/list/" + status + "/" + area_id,
          title: detail?.area_name
        },
        {
          title:
            General.loading === false && detail?.start_date && detail?.end_date
              ? activityMonth(detail?.start_date, detail?.end_date)
              : ""
        }
      ])
    }
  }, [detail])

  const changePage = useCallback(
    (param) => {
      let newParam = {
        ...param
      }
      if (param.sortField) {
        newParam = {
          ...newParam,
          sort: param.sortField + "," + param.sortOrder
        }
      }
      dispatch(getHistoryPlantingSeason(id, { pageReq: newParam }))
    },
    [dispatch]
  )

  useEffect(() => {
    subHeader.setButton(true)
  }, [])

  const handleClickDetail = (id, type) => {
    if (type === ORDER_STATUS_PLANTING_ENUM.CLEARING_AREA) {
      history.push(RoutesConstants.AREA.DETAIL_ACTIVITY_LAND_CLEARING(status, area_id, id))
    } else {
      history.push("/lahan/" + status + "/" + area_id + "/" + type + "/" + id)
    }
  }

  function checkIcon(dataDetail) {
    if (dataDetail?.is_harvest_failure) {
      return dataDetail?.plant_type?.toLowerCase() === PLANT_TYPE_STRING.CORN ||
        dataDetail?.plant_type.toLowerCase() === PLANT_TYPE_STRING.PROD_CORN
        ? ICON_CONSTANS.IC_CORN_O_GRAY
        : ICON_CONSTANS.IC_PADDY_O_GRAY
    } else {
      return dataDetail?.plant_type?.toLowerCase() === PLANT_TYPE_STRING.PADDY
        ? ICON_CONSTANS.IC_PADDY_O
        : ICON_CONSTANS.IC_CORN_O
    }
  }

  return (
    <>
      <Card>
        <CardBody>
          <RowModule>
            <div className="d-flex justify-content-between">
              <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div className="symbol symbol-50 symbol-lg-120">
                  <FlatIconAndText
                    text={
                      <span className="font-size-h3">
                        Musim Tanam{" "}
                        {General.loading === false && detail?.start_date && detail?.end_date
                          ? activityMonth(detail?.start_date, detail?.end_date)
                          : ""}
                      </span>
                    }
                    svg={checkIcon(detail)}
                  />
                  {General.loading ? (
                    Array.from({ length: 2 }).map((item, index) => {
                      return (
                        <div key={index} className="d-flex mt-8 flex-column flex-grow-1 pr-8">
                          <Skeleton animation="wave" variant={"text"} />
                          <Skeleton animation="wave" variant={"text"} />
                        </div>
                      )
                    })
                  ) : (
                    <RowModule customColumnCss={"mx-6"}>
                      <div className="d-flex mt-8 flex-column flex-grow-1 pr-8">
                        <span className="mb-2">Varietas</span>
                        <span className="font-size-h3 font-weight-bold">{detail?.variety}</span>
                      </div>
                      <div className="d-flex mt-8 flex-column flex-grow-1 pr-8">
                        <span className="mb-2">Jumlah Aktivitas</span>
                        <span className="font-size-h3 font-weight-bold">
                          {detail?.total_activity}
                        </span>
                      </div>
                      <div className="d-flex mt-8 flex-column flex-grow-1 pr-8">
                        <span className="mb-2">Konfirmasi Musim Tanam</span>
                        <span className="font-size-h3 font-weight-bold">
                          {checkValue(
                            timeStampToDate(
                              detail?.planting_season_created_date,
                              DATE_TYPE_ENUM.ISO_DATE_STRAP
                            )
                          )}
                        </span>
                      </div>
                    </RowModule>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`d-flex justify-content-end h-100 ${
                detail?.is_harvest_failure ? "align-items-start" : "align-items-end"
              } `}>
              {detail?.is_harvest_failure ? (
                <Status text={"Gagal Panen"} variant={"danger"} />
              ) : (
                <div className="col-10">
                  <RowModule>
                    {privileges.indexOf(PRIVILEGE_ENUM.HARVEST_TOTAL) > -1 && (
                      <div className="d-flex flex-column flex-grow-1 pr-8">
                        <span className="mb-2">
                          {General.loading ? (
                            <Skeleton animation="wave" variant={"text"} height={24} />
                          ) : (
                            <div className={"d-flex"}>
                              <div className={"mr-2"}>{t("label.netto")}</div>
                              {detail?.plant_type &&
                                detail?.plant_type.toLowerCase() ===
                                  PLANT_TYPE_STRING.PROD_CORN && (
                                  <Tooltip
                                    arrow={true}
                                    placement={"top"}
                                    title={t("messages.alert.netto_alert_weight_slip")}>
                                    <img src={ICON_CONSTANS.IC_INFORMATION} alt={""} />
                                  </Tooltip>
                                )}
                            </div>
                          )}
                        </span>
                        <span className="font-size-h3 font-weight-bold">
                          {General.loading ? (
                            <Skeleton animation="wave" variant={"text"} height={24} />
                          ) : (
                            checkValue(
                              detail?.is_seed_production
                                ? toThousandConvert(detail?.netto)
                                : toThousandConvert(detail?.total_harvest),
                              "Kg"
                            )
                          )}
                        </span>
                      </div>
                    )}
                    {privileges.indexOf(PRIVILEGE_ENUM.TOTAL_INCOME) > -1 && (
                      <div className="d-flex flex-column flex-grow-1 pr-8">
                        <span className="mb-2">
                          {General.loading ? (
                            <Skeleton animation="wave" variant={"text"} />
                          ) : (
                            "Total Pendapatan"
                          )}
                        </span>
                        <span className="font-size-h3 font-weight-bold">
                          {General.loading ? (
                            <Skeleton animation="wave" variant={"text"} height={24} />
                          ) : detail?.total_income ? (
                            toRupiah(detail?.total_income)
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                    )}
                  </RowModule>
                </div>
              )}
            </div>
          </RowModule>
        </CardBody>
      </Card>
      <Card>
        <RowModule>
          <AreaList
            data={Area}
            general={General}
            changePage={changePage}
            onClickDetail={handleClickDetail}
            history={history}
          />
        </RowModule>
      </Card>
    </>
  )
}
