import { all } from "redux-saga/effects"
import { combineReducers } from "redux"

import * as auth from "../component/template/Auth/_redux/authRedux"
import { customersSlice } from "../component/template/ECommerce/_redux/customers/customersSlice"
import { productsSlice } from "../component/template/ECommerce/_redux/products/productsSlice"
import { remarksSlice } from "../component/template/ECommerce/_redux/remarks/remarksSlice"
import { specificationsSlice } from "../component/template/ECommerce/_redux/specifications/specificationsSlice"
import { dataSlice } from "../app/service/baseAxiosAction"
import { userDataSlice } from "../app/service/userDataAction"
import { GeneralSlice } from "./reducers/General"
import { FieldOfficerSlice } from "./reducers/FieldOfficer"
import { MitraListSlice, MitraSlice } from "./reducers/Mitra"
import { MasterDataSlice } from "./reducers/MasterData"
import { FarmerSlice } from "./reducers/Farmer"
import { AreaSlice } from "./reducers/Area"
import { AreaMapSlice } from "./reducers/AreaMap"
import { AgendaSlice } from "./reducers/Agenda"
import { RiwayatSlice } from "./reducers/Riwayat"
import { RoleSlice } from "./reducers/Role"
import { AccountSlice } from "./reducers/Account"
import { VideoSlice } from "./reducers/Video"
import { LogSlice } from "./reducers/Log"
import { BroadcastSlice } from "./reducers/Broadcast"
import { UrlParam } from "./reducers/UrlParam"
import { OrderSlice } from "./reducers/Order"
import { MacSlice } from "./reducers/Mac"
import { SubmissionSlice } from "./reducers/Submission.jsx"
import { ReportActivitySlice } from "./reducers/ReportActivity.jsx"

export const rootReducer = combineReducers({
  auth: auth.reducer,
  General: GeneralSlice.reducer,
  MasterData: MasterDataSlice.reducer,
  Mitra: MitraSlice.reducer,
  Mitralist: MitraListSlice.reducer,
  FieldOfficer: FieldOfficerSlice.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  dataService: dataSlice.reducer,
  userData: userDataSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  Farmer: FarmerSlice.reducer,
  Area: AreaSlice.reducer,
  Areamap: AreaMapSlice.reducer,
  Agenda: AgendaSlice.reducer,
  Riwayat: RiwayatSlice.reducer,
  Role: RoleSlice.reducer,
  Account: AccountSlice.reducer,
  Video: VideoSlice.reducer,
  Log: LogSlice.reducer,
  Broadcast: BroadcastSlice.reducer,
  UrlParam: UrlParam.reducer,
  Order: OrderSlice.reducer,
  Mac: MacSlice.reducer,
  Submission: SubmissionSlice.reducer,
  ReportActivity: ReportActivitySlice.reducer
})

export function* rootSaga() {
  yield all([auth.saga()])
}
