import React from "react"
import { Backdrop } from "@material-ui/core"
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/index.js"
import IconButton from "@material-ui/core/IconButton"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_metronic/_helpers/index.js"
import { useTranslation } from "react-i18next"
import { ICON_CONSTANS } from "../../../constants/ImageConstants.js"
import { AddMenuItem } from "../../atoms/AddMenuItem/AddMenuItem.jsx"
import { listMenuAdd } from "../../../app/service/userManagementAction.js"

export const AddMenu = ({ show, setShowAdd, data }) => {
  const { t } = useTranslation()
  return (
    <>
      {show && data.length && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => setShowAdd(false)}>
          <div className={`add-component ${show ? "active" : ""}`}>
            <Card className={`card-custom`}>
              <CardHeader>
                <div className="d-flex flex-wrap justify-content-between align-items-center w-full">
                  <h4 className="card-label mb-0">{t("menu.quick_access")}</h4>
                  <IconButton
                    className={"icon-close"}
                    aria-label="Search"
                    onClick={() => setShowAdd(false)}>
                    <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_CLOSE)} />
                  </IconButton>
                </div>
              </CardHeader>
              <CardBody className={"filter-body"}>
                {data.length &&
                  data.map((item, index) => {
                    return (
                      listMenuAdd(item.parent).length && (
                        <div key={index}>
                          <div className={`h6-font-size font-weight-bold px-lg-4`}>
                            {item.title}
                          </div>
                          <div className={`card-body filter-body d-flex flex-wrap w-fit`}>
                            {listMenuAdd(item.parent).length &&
                              listMenuAdd(item.parent).map((item, index) => {
                                return <AddMenuItem data={item} key={index} />
                              })}
                          </div>
                        </div>
                      )
                    )
                  })}
              </CardBody>
            </Card>
          </div>
        </Backdrop>
      )}
    </>
  )
}
