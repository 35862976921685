import React from "react"
import { ICON_CONSTANS } from "../../../constants/ImageConstants.js"
import { Button } from "react-bootstrap"

export const FloatingSelectArea = ({
  totalArea,
  totalLandArea,
  buttonlabel,
  handleClick,
  withIcon,
  icon,
  labelIcon,
  buttonColor,
  frontIcon,
  label,
  disableButton = false,
  showInfo,
  infoText
}) => {
  return (
    <div className={`floating-select-area`}>
      <div className={"d-flex justify-content-between align-items-center"}>
        <div className={"d-flex align-items-center"}>
          <img src={labelIcon || ICON_CONSTANS.IC_MAP_WHITE} alt={""} className={"mr-2"} />
          <div className={"color-white d-flex text-lowercase font-size-sm"}>
            {totalArea} {label ? label : "lahan terpilih"}{" "}
            <span className={"font-weight-light ml-1"}>{totalLandArea}</span>
          </div>
        </div>
        <div className={"d-flex align-items-center"}>
          {showInfo && infoText}
          <Button
            disabled={disableButton}
            onClick={handleClick}
            className={"d-flex align-items-center color-white ml-4"}
            variant={buttonColor ?? "success"}>
            {frontIcon && frontIcon}
            {buttonlabel}{" "}
            {withIcon && (
              <img src={icon ? icon : ICON_CONSTANS.IC_ARROW_RIGHT_WHITE} className={"ml-2"} />
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
