import { REPORT_ACTIVITY } from "../../constants/InitTypeConstants.js"
import { requestGet, requestPost } from "../../config/index.jsx"
import { MASTER_URL, REPORT_URL } from "../../constants/UrlConstants.js"
import { GeneralSlice } from "../reducers/General.jsx"
import { ReportActivitySlice } from "../reducers/ReportActivity.jsx"

const { actions: general } = GeneralSlice
const { actions: reportActivity } = ReportActivitySlice

export const getMitraListCreateBaLandClearing = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: REPORT_ACTIVITY.GET_LIST_MITRA
      })
    )
    try {
      let res = await requestGet(REPORT_URL.GET_LIST_MITRA_FOR_BA_LAND_CLEARING + param)
      dispatch(reportActivity.setFilterMitra(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: REPORT_ACTIVITY.GET_LIST_MITRA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export function downloadReportCsv(data) {
  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: REPORT_ACTIVITY.DOWNLOAD_CSV }))
    requestPost(REPORT_URL.DOWNLOAD_REPORT_CSV, data)
      .then((res) => {
        dispatch(reportActivity.setCsvDataDownload(res))
        dispatch(
          general.setLoading({ loading: false, actionInitType: REPORT_ACTIVITY.DOWNLOAD_CSV })
        )
      })
      .catch((e) => {
        general.setLoading({ loading: false, actionInitType: REPORT_ACTIVITY.DOWNLOAD_CSV })

        dispatch(general.setError(e))
      })
  }
}

export const getListAreaBaLandClearing = (id, param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: REPORT_ACTIVITY.GET_LIST_AREA_BA_LAND_CLEARING
      })
    )
    await requestGet(REPORT_URL.GET_AREA_LAND_CLEARING_BY_MITRA + id + param)
      .then((res) => {
        dispatch(
          reportActivity.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: REPORT_ACTIVITY.GET_LIST_AREA_BA_LAND_CLEARING
          })
        )
      })
      .catch(() => {
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: REPORT_ACTIVITY.GET_LIST_AREA_BA_LAND_CLEARING
          })
        )
      })
  }
}

export const createBaLandClearing = (body) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: REPORT_ACTIVITY.CREATE_BA_LAND_CLEARING
      })
    )
    try {
      let res = await requestPost(REPORT_URL.CREATE_BA_LAND_CLEARING, body).then(() => {
        dispatch(reportActivity.setResponseCreateBa(res?.data))

        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: REPORT_ACTIVITY.CREATE_BA_LAND_CLEARING
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: REPORT_ACTIVITY.CREATE_BA_LAND_CLEARING
          })
        )
      })
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListReasonReportBa = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: REPORT_ACTIVITY.GET_LIST_REASON_REPORT_BA
      })
    )
    try {
      let res = await requestGet(REPORT_URL.GET_LIST_REASON)
      dispatch(reportActivity.setListReason(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: REPORT_ACTIVITY.GET_LIST_REASON_REPORT_BA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListPest = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: REPORT_ACTIVITY.GET_LIST_PEST
      })
    )
    try {
      let res = await requestGet(MASTER_URL.GET_PLANTS + `/${param}`)
      dispatch(reportActivity.setListPest(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: REPORT_ACTIVITY.GET_LIST_PEST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListChildReason = (param, index) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: REPORT_ACTIVITY.GET_LIST_REASON_REPORT_BA
      })
    )
    try {
      let res = await requestGet(REPORT_URL.GET_LIST_REASON + param)
      dispatch(
        reportActivity.setListChildReason({
          index: index,
          data: res.data
        })
      )
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: REPORT_ACTIVITY.GET_LIST_REASON_REPORT_BA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
