import SVG from "react-inlinesvg"
import React from "react"
import { ICON_CONSTANS } from "../../../constants/ImageConstants"

export const NoteView = ({ className, icon, title, variant = "success", titleClassName }) => {
  function checkIcons() {
    if (!icon) {
      switch (variant) {
        case "success":
          return ICON_CONSTANS.IC_NOTE_VIEW_SUCCESS
        case "primary":
          return ICON_CONSTANS.IC_NOTE_VIEW_PRIMARY
        case "danger":
          return ICON_CONSTANS.IC_NOTE_VIEW_DANGER
        case "warning":
          return ICON_CONSTANS.IC_NOTE_VIEW_WARNING
        case "info":
          return ICON_CONSTANS.IC_NOTE_VIEW_INFO
        case "completed":
          return ICON_CONSTANS.IC_NOTE_VIEW_COMPLETED
        default:
          return ICON_CONSTANS.IC_NOTE_VIEW_SUCCESS
      }
    } else {
      return icon
    }
  }

  return (
    <>
      <div className={`alert mb-6 w-100 ${className}`}>
        <div className={`alert-item alert-${variant} d-flex align-items-center`}>
          {checkIcons() && <SVG className="mr-2" src={checkIcons()} />}
          <div className={`text ${titleClassName}`} style={{ whiteSpace: "pre-line" }}>
            {title}
          </div>
        </div>
      </div>
    </>
  )
}
