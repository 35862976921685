import { useHtmlClassService } from "../../../../../_metronic/layout/index.js"
import React, { useEffect, useState } from "react"
import { MenuBack } from "../../../../../component/atoms/MenuBack/index.jsx"
import { Stepper } from "../../../../../component/atoms/Stepper/index.jsx"
import { useTranslation } from "react-i18next"
import { SelectAreaBa } from "./step-form/SelectAreaBa.jsx"
import { useHistory } from "react-router-dom"
import { FillAreaDetail } from "./step-form/FillAreaDetail.jsx"
import { useFormik } from "formik"
import * as yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../helpers/StringCollection.js"
import { useDispatch, useSelector } from "react-redux"
import Overview from "./step-form/Overview.jsx"
import { createBaLandClearing } from "../../../../../redux/actions/ReportActivityAction.jsx"
import ModalConfirmation from "./components/ModalConfirmation.jsx"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/AssetsHelpers.js"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
import { REPORT_ACTIVITY } from "../../../../../constants/InitTypeConstants.js"

export const CreateReportBaPage = () => {
  const uiService = useHtmlClassService()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)
  const [selectedMitra, setSelectedMitra] = useState(null)
  const [dataInit, setDataInit] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [selectedArea, setSelectedArea] = useState([])
  const [showModalConfirmation, setModalConfirmation] = useState(false)
  
  const [fileAttachments, setFileAttachments] = useState([])

  const formik = useFormik({
    initialValues: {
      area: [],
      document_url: ""
    },
    validationSchema: yup.object({
      area: yup.array().of(
        yup.object({
          area_id: yup.string().nullable(),
          activity_date: yup.string().nullable(),
          reason_id: yup
            .string()
            .nullable()
            .required(getStringErrorMassage("Alasan Bongkar", FORM_NEED.harusDiIsi)),
          pest_id: yup.array().nullable().required(),
          reason_string: yup.string().nullable().required()
        })
      ),
      document_url: yup.string().nullable().required(),
      document_name: yup.string().nullable().required(),
      document_size: yup.string().nullable().required()
    }),
    onSubmit: (values) => {
      const areaList = values.area.map((item) => {
        return {
          area_id: item.id,
          activity_date: item.activity_date,
          reason_id: item.reason_id,
          reason_id_child: item.reason_id_child,
          pest_id: item.pest_id,
          reason_string: item.reason_string
        }
      })
      const body = {
        area: areaList,
        document_url: values.document_url
      }
      dispatch(createBaLandClearing(body))
    }
  })

  useEffect(() => {
    if (dataInit) {
      formik.setFieldValue("area", dataInit.area)
    }
  }, [dataInit])

  const handleSubmitForm = () => {
    const values = formik.values

    const areaList = values.area.map((item) => {
      return {
        area_id: item.id,
        activity_date: item.activity_date,
        reason_id: item.reason_id_child ? item.reason_id_child : item.reason_id,
        pest_ids: item.pest_id || null,
        reason_string: item.reason_string || null
      }
    })
    const body = {
      area: areaList,
      document_url: values.document_url,
      document_name: values.document_name,
      document_size: Number(values.document_size)
    }
    dispatch(createBaLandClearing(body))
  }

  const labelStep = ["Pilih Lahan", "Isi Detail Lahan", "Unggah Berita Acara"]
  useEffect(() => {
    uiService.setFullPage()
    return () => {
      uiService.setExitFullPage()
    }
  }, [])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === REPORT_ACTIVITY.CREATE_BA_LAND_CLEARING) {
        setModalConfirmation(false)
        swal(t("messages.response.success_create_clearing_report"), {
          icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.farmer.IL_SUCCESS_CREATE_REPORT),
          button: "Oke"
        }).then(() => {
          history.push(RoutesConstants.REPORT.LIST_BA_SEEDING())
        })
      }
    }
  }, [General])

  const header = (
    <div
      className={
        "bg-white d-flex justify-content-between align-content-center p-6 border-1 border-bottom border-bottom-light-dark"
      }>
      <MenuBack
        title={t("label.create_ba_land_clearing")}
        onClick={() => {
          uiService.setExitFullPage()
          history.goBack()
        }}
      />
      <Stepper step={labelStep} activeStep={activeStep} setActiveStep={setActiveStep} />
    </div>
  )

  const dataStepBody = [
    <SelectAreaBa
      key={0}
      selectedArea={selectedArea}
      setSelectedArea={setSelectedArea}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      setDataInit={setDataInit}
      selectedMitra={selectedMitra}
      setSelectedMitra={setSelectedMitra}
      dataInit={dataInit}
    />,
    <FillAreaDetail
      key={1}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      formik={formik}
      dispatch={dispatch}
      dataInit={dataInit}
    />,
    <Overview
      key={2}
      activeStep={activeStep}
      formik={formik}
      setActiveStep={setActiveStep}
      dispatch={dispatch}
      dataInit={dataInit}
      selectedArea={selectedArea}
      setSelectedArea={setSelectedArea}
      setDataInit={setDataInit}
      setModalConfirmation={setModalConfirmation}
      fileAttachments={fileAttachments}
      setFileAttachments={setFileAttachments}
    />
  ]

  const getBodyStep = (step) => {
    return dataStepBody[step]
  }
  return (
    <>
      <div>
        {header}
        {getBodyStep(activeStep)}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <ModalConfirmation
          showModalConfirmation={showModalConfirmation}
          setModalConfirmation={setModalConfirmation}
          onSubmitForm={handleSubmitForm}
        />
      </form>
    </>
  )
}
