import { GeneralSlice } from "../reducers/General"
import { AreaSlice } from "../reducers/Area"
import { AreaMapSlice } from "../reducers/AreaMap"
import { AREA } from "../../constants/InitTypeConstants"
import { requestGet, requestPatch, requestPost, requestPut } from "../../config"
import { AREA_URL, MASTER_DATA_URL } from "../../constants/UrlConstants"
import { getUrlParsingActivity, getUrlParsingPageFilter } from "../../config/EndpointCollection.js"
import { MasterDataSlice } from "../reducers/MasterData.jsx"

const { actions: general } = GeneralSlice
const { actions: area } = AreaSlice
const { actions: areamap } = AreaMapSlice
const { actions: master } = MasterDataSlice

function setEmptyPageDataBeforeFetching(dispatch) {
  dispatch(
    area.setPageData({
      pageData: [],
      page: {
        totalCount: 0,
        currentElement: 0,
        currentPage: 0
      }
    })
  )
}

function setEmptyPageDataActivity(dispatch) {
  dispatch(
    area.setPageDataActivity({
      pageData: [],
      page: {
        totalCount: 0,
        currentElement: 0,
        currentPage: 0
      }
    })
  )
}

export function getDetailLandClearing(id) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_DETAIL_LAND_CLEARING
      })
    )
    await requestGet(AREA_URL.GET_DETAIL_LAND_CLEARING(id))
      .then((res) => {
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_DETAIL_LAND_CLEARING
          })
        )
        dispatch(area.getPage(res.data))
      })
      .catch((e) => {
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_DETAIL_LAND_CLEARING
          })
        )
        dispatch(general.setError(e))
      })
  }
}

export const getAreaMap = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param)
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: AREA.GET_ALL_FOR_COMBOBOX
      })
    )
    try {
      let res = await requestGet(AREA_URL.GET_LIST_MAP_NEW + urlData)
      dispatch(
        areamap.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: AREA.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getAreaVerifiedList = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ALL_FOR_COMBOBOX
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_LIST_VERIFIED + param)
      dispatch(
        area.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getAreaKurList = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ALL_FOR_COMBOBOX
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_LIST_KUR + param)
      dispatch(
        area.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getAreaNonKurList = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ALL_FOR_COMBOBOX
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_LIST_NON_KUR + param)
      dispatch(
        area.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getAreaPendingList = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_LIST_PENDING
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_LIST_PENDING + urlData)
      dispatch(
        area.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_PENDING
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getAreaPendingUnassignList = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_LIST_PENDING_UNASSIGN
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_LIST_PENDING_UNASSIGN + urlData)
      dispatch(
        area.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_PENDING_UNASSIGN
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getAreaRejectList = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_LIST_REJECT
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_LIST_REJECTED + urlData)
      dispatch(
        area.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_REJECT
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const updateUnassign = (id, reasons, other) => {
  const payload = {
    area_id: id,
    area_unassigned_enum: reasons,
    reason: other
  }

  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: AREA.UPDATE_STATUS_FARMER
      })
    )
    try {
      let res = await requestPut(AREA_URL.UPDATE_UNASSIGN, null, payload)
      dispatch(area.getRejectReason(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: AREA.UPDATE_STATUS_FARMER
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: AREA.UPDATE_STATUS_FARMER
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getAreaUnassignList = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_LIST_UNASSIGN
      })
    )
    try {
      let res = await requestGet(AREA_URL.GET_LIST_UNASSIGN)
      dispatch(area.getRejectReason(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_UNASSIGN
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getRejectReasons = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_LIST_REJECT_REASON
      })
    )
    try {
      let res = await requestGet(AREA_URL.GET_LIST_REJECT_REASON)
      dispatch(area.getRejectReason(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_REJECT_REASON
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const updateStatus = (id, status, reasons = []) => {
  const payload = {
    status,
    area_reject_enum_list: reasons
  }
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: AREA.UPDATE_STATUS }))
    try {
      let res = await requestPut(AREA_URL.UPDATE_STATUS + id, null, payload)
      dispatch(area.getRejectReason(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: AREA.UPDATE_STATUS
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: AREA.UPDATE_STATUS
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
export const updateStatusUnassign = (id, status) => {
  const payload = {
    status
  }
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: AREA.UPDATE_STATUS }))
    try {
      let res = await requestPut(AREA_URL.UPDATE_STATUS_UNASSIGNED + id, null, payload)
      dispatch(area.getRejectReason(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: AREA.UPDATE_STATUS
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: AREA.UPDATE_STATUS
        })
      )
    } catch (e) {
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: AREA.UPDATE_STATUS
        })
      )
      dispatch(general.setError(e))
    }
  }
}
export const getAreaUnassignedList = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_LIST_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_LIST_UNASSIGNED + urlData)
      dispatch(
        area.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(
        area.setPageData({
          pageData: [],
          page: {}
        })
      )
      dispatch(general.setError(e))
    }
  }
}

export const getDetail = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: AREA.GET_DETAIL
        })
      )
      dispatch(area.getPage(null))
      try {
        let res = await requestGet(AREA_URL.GET_DETAIL_AREA_V3 + param)
        dispatch(area.getPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_DETAIL
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getMapDetail = (param) => {
  return async (dispatch) => {
    if (param) {
      try {
        let res = await requestGet(AREA_URL.GET_DETAIL_MAP_AREA + param)
        dispatch(area.getMapDetail(res.data.map_areas))
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const clearDataDetail = () => {
  return async (dispatch) => {
    dispatch(area.getPage(false))
  }
}

export const clearDataDetailMap = () => {
  return async (dispatch) => {
    dispatch(area.getMapDetail(undefined))
  }
}

export const editDatePlantArea = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: AREA.UPDATE_DATE_PLANT_AREA
        })
      )
      try {
        await requestPut(AREA_URL.UPDATE_DATE_PLANT_AREA, null, data)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: AREA.UPDATE_DATE_PLANT_AREA
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: AREA.UPDATE_DATE_PLANT_AREA
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getDetailPlantingEvent = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: AREA.GET_DETAIL_PLANTING_EVENT_AREA
        })
      )
      try {
        let res = await requestGet(AREA_URL.GET_DETAIL_PLANTING_EVENT_AREA + param)
        dispatch(area.getPlantingEvent(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_DETAIL_PLANTING_EVENT_AREA
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getAreaLeadList = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_LIST_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_LIST_LEAD + urlData)
      dispatch(
        area.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(
        area.setPageData({
          pageData: [],
          page: {}
        })
      )
      dispatch(general.setError(e))
    }
  }
}

export const getDetailLead = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: AREA.GET_DETAIL
        })
      )
      try {
        let res = await requestGet(AREA_URL.GET_LIST_LEAD_DETAIL + param)
        dispatch(area.getPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_DETAIL
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getDetailUnassign = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: AREA.GET_DETAIL
        })
      )
      try {
        let res = await requestGet(AREA_URL.GET_LIST_UNASSIGN_DETAIL + param)
        dispatch(area.getPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_DETAIL
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const updateImage = ({ id, data }) => {
  return async (dispatch) => {
    try {
      dispatch(general.setLoading({ loading: true, actionInitType: AREA.UPDATE_IMAGE }))
      await requestPut(AREA_URL.UPDATE_IMAGE + id, null, data)
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.UPDATE_IMAGE
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: AREA.UPDATE_IMAGE
        })
      )
    } catch (e) {
      dispatch(general.setError({ actionInitType: AREA.UPDATE_IMAGE, e }))
    }
  }
}

export const updateAreaActivityImage = (data) => {
  const url = AREA_URL.UPDATE_ACTIVITY_IMAGE + data.id
  return async (dispatch) => {
    try {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: AREA.UPDATE_ACTIVITY_IMAGE
        })
      )
      await requestPut(url, null, data)
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.UPDATE_ACTIVITY_IMAGE
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: AREA.UPDATE_ACTIVITY_IMAGE
        })
      )
    } catch (e) {
      dispatch(general.setError({ actionInitType: AREA.UPDATE_ACTIVITY_IMAGE, e }))
    }
  }
}

export const getListActivity = (id, param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingActivity(param)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_LIST_ACTIVITY
      })
    )
    try {
      let res = await requestGet(AREA_URL.GET_LIST_ACTIVITY + id + urlData)
      dispatch(area.setActivityList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_LIST_ACTIVITY
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const confirmationSeasonPlanting = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: AREA.PLANTING_SEASON_CONFIRMATION
      })
    )
    try {
      let res = await requestPatch(AREA_URL.PLANTING_SEASON_CONFIRMATION + id)
      dispatch(area.setConfirm(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: AREA.PLANTING_SEASON_CONFIRMATION
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: AREA.PLANTING_SEASON_CONFIRMATION
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailHarvestEstimation = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_DETAIL_ACTIVITY
      })
    )
    dispatch(area.getPage(null))
    try {
      let res = await requestGet(AREA_URL.GET_HARVEST_ESTIMATION + id)
      dispatch(area.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_DETAIL_ACTIVITY
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
export const getDetailHarvest = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_DETAIL_ACTIVITY
      })
    )
    dispatch(area.getPage(null))
    try {
      let res = await requestGet(AREA_URL.GET_HARVEST + id)
      dispatch(area.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_DETAIL_ACTIVITY
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailFertilization = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_DETAIL_ACTIVITY
      })
    )
    dispatch(area.getPage(null))
    try {
      let res = await requestGet(AREA_URL.GET_FERTILIZATION + id)
      dispatch(area.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_DETAIL_ACTIVITY
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailSpraying = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_DETAIL_ACTIVITY
      })
    )
    dispatch(area.getPage(null))
    try {
      let res = await requestGet(AREA_URL.GET_SPRAYING + id)
      dispatch(area.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_DETAIL_ACTIVITY
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailPlanting = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_DETAIL_ACTIVITY
      })
    )
    dispatch(area.getPage(null))
    try {
      let res = await requestGet(AREA_URL.GET_PLANTING + id)
      dispatch(area.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_DETAIL_ACTIVITY
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailSeedling = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_DETAIL_ACTIVITY
      })
    )
    dispatch(area.getPage(null))
    try {
      let res = await requestGet(AREA_URL.GET_SEEDLING + id)
      dispatch(area.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_DETAIL_ACTIVITY
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getHistoryActivityList = (id, param) => {
  let urlData = getUrlParsingActivity(param)
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_HISTORY_ACTIVITY
      })
    )
    try {
      let res = await requestGet(AREA_URL.GET_HISTORY_ACTIVITY_LIST + id + urlData)
      dispatch(area.setHistoryList(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_HISTORY_ACTIVITY
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getHistoryPlantingSeasonHeader = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_HISTORY_PLANTING_SEASON
      })
    )
    try {
      let res = await requestGet(AREA_URL.GET_HISTORY_PLANTING_SEASON_HEADER + id)
      dispatch(area.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_HISTORY_PLANTING_SEASON
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getHistoryPlantingSeason = (id, param) => {
  let urlData = getUrlParsingPageFilter(param.pageReq)
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_HISTORY_PLANTING_SEASON
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_HISTORY_PLANTING_SEASON + id + urlData)
      dispatch(
        area.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_HISTORY_PLANTING_SEASON
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListSeedingBaReport = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_LIST_SEEDING_BA_REPORT
      })
    )
    await requestGet(AREA_URL.GET_ACTIVITY_LAND_BA_REPORT() + param)
      .then((res) => {
        dispatch(
          area.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_LIST_SEEDING_BA_REPORT
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))

        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_LIST_SEEDING_BA_REPORT
          })
        )
      })
  }
}

export const getActivityLandKur = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_LIST_ACTIVITY_KUR
      })
    )
    setEmptyPageDataActivity(dispatch)
    await requestGet(AREA_URL.GET_ACTIVITY_LAND_LAND_KUR + param)
      .then((res) => {
        dispatch(
          area.setPageDataActivity({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_LIST_ACTIVITY_KUR
          })
        )
      })
      .catch(() => {
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_LIST_ACTIVITY_KUR
          })
        )
      })
  }
}

export const getActivityLandList = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ACTIVITY_LAND
      })
    )
    setEmptyPageDataActivity(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_ACTIVITY_LAND + param)
      dispatch(
        area.setPageDataActivity({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_ACTIVITY_LAND
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getActivityWaiting = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ACTIVITY_LAND
      })
    )
    setEmptyPageDataActivity(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_ACTIVITY_WAITING + param)
      dispatch(
        area.setPageDataActivity({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_ACTIVITY_LAND
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListNeedBa = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ACTIVITY_LAND
      })
    )
    setEmptyPageDataActivity(dispatch)
    await requestGet(AREA_URL.LIST_ACTIVITY_NEED_BA() + param)
      .then((res) => {
        dispatch(
          area.setPageDataActivity({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_ACTIVITY_LAND
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const getListNoBill = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ACTIVITY_LAND
      })
    )
    setEmptyPageDataActivity(dispatch)
    await requestGet(AREA_URL.LIST_ACTIVITY_NO_BILL() + param)
      .then((res) => {
        dispatch(
          area.setPageDataActivity({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_ACTIVITY_LAND
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const getListReadySendFinance = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ACTIVITY_LAND
      })
    )
    setEmptyPageDataActivity(dispatch)
    await requestGet(AREA_URL.LIST_ACTIVITY_READY_SEND_FINANCE() + param)
      .then((res) => {
        dispatch(
          area.setPageDataActivity({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_ACTIVITY_LAND
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}
export const getListSendingFinance = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ACTIVITY_LAND
      })
    )
    setEmptyPageDataActivity(dispatch)
    await requestGet(AREA_URL.LIST_ACTIVITY_SENDING_FINANCE() + param)
      .then((res) => {
        dispatch(
          area.setPageDataActivity({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: AREA.GET_ACTIVITY_LAND
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const getActivityApproved = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ACTIVITY_LAND
      })
    )
    setEmptyPageDataActivity(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_ACTIVITY_APPROVED + param)
      dispatch(
        area.setPageDataActivity({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_ACTIVITY_LAND
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getActivityManual = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_ACTIVITY_LAND
      })
    )
    setEmptyPageDataActivity(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_ACTIVITY_MANUAL + param)
      dispatch(
        area.setPageDataActivity({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_ACTIVITY_LAND
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getFailureHarvest = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_DETAIL_ACTIVITY
      })
    )
    try {
      let res = await requestGet(AREA_URL.GET_FAILURE_HARVEST + id)
      dispatch(area.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_DETAIL_ACTIVITY
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailTillage = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_DETAIL_ACTIVITY
      })
    )
    try {
      let res = await requestGet(AREA_URL.GET_TILLAGE + id)
      dispatch(area.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_DETAIL_ACTIVITY
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPlantingStatusEnum = () => {
  return async (dispatch) => {
    try {
      const res = await requestGet(AREA_URL.GET_PLANTING_STATUS)
      dispatch(area.setPlantingStatusEnum(res.data))
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const activitySendToErp = (id, body) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: AREA.APPROVE_ACTIVITY_TO_ERP
      })
    )
    dispatch(
      general.finishUpdateApi({
        isFinishUpdateOrDelete: false,
        actionInitType: AREA.APPROVE_ACTIVITY_TO_ERP
      })
    )
    try {
      let res = await requestPut(AREA_URL.SEND_ACTIVITY_TO_ERP + id, null, body)
      dispatch(area.setConfirm(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: AREA.APPROVE_ACTIVITY_TO_ERP
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: AREA.APPROVE_ACTIVITY_TO_ERP
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export function cancelPlatingSeason(id) {
  return async (dispatch) => {
    if (id) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: AREA.CANCEL_PLANTING_SEASON
        })
      )
      try {
        await requestPatch(AREA_URL.CANCEL_PLANTING_SEASON + id)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: AREA.CANCEL_PLANTING_SEASON
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: AREA.CANCEL_PLANTING_SEASON
          })
        )
      } catch (e) {
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: AREA.CANCEL_PLANTING_SEASON
          })
        )
        dispatch(
          general.setError({
            customModal: true,
            message: e.message
          })
        )
      }
    }
  }
}

export function createSoActions(data) {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: AREA.CREATE_SO
        })
      )
      await requestPost(AREA_URL.CREATE_SO, data)
        .then(() => {
          dispatch(
            general.setLoadingGlobal({
              loading: false,
              actionInitType: AREA.CREATE_SO
            })
          )
          dispatch(
            general.finishUpdateApi({
              isFinishUpdateOrDelete: true,
              actionInitType: AREA.CREATE_SO
            })
          )
        })
        .catch((e) => {
          dispatch(general.setError(e))
        })
    }
  }
}

export const createFailedHarvest = (id, data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: AREA.CREATE_FAILED_HARVEST
        })
      )
      try {
        let res = await requestPost(AREA_URL.CREATE_FAILED_HARVEST + id, data)
        dispatch(area.setResponsePost(res.data))
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: AREA.CREATE_FAILED_HARVEST
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: AREA.CREATE_FAILED_HARVEST
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const activityBulkList = (data) => {
  return async (dispatch) => {
    dispatch(area.setBulkList(data))
  }
}

export const activitySendToErpBulk = (data) => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingGlobal({
        loading: true,
        actionInitType: AREA.APPROVE_ACTIVITY_TO_ERP_BULK
      })
    )
    dispatch(
      general.finishUpdateApi({
        isFinishUpdateOrDelete: false,
        actionInitType: AREA.APPROVE_ACTIVITY_TO_ERP_BULK
      })
    )
    try {
      let res = await requestPost(AREA_URL.APPROVE_ACTIVITY_BULK, data)
      dispatch(area.setConfirm(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: AREA.APPROVE_ACTIVITY_TO_ERP_BULK
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: AREA.APPROVE_ACTIVITY_TO_ERP_BULK
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPlantTypeFilter = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: AREA.GET_PLANT_TYPE
      })
    )
    try {
      let res = await requestGet(MASTER_DATA_URL.GET_PLANT_TYPE_FILTER_AREA)
      dispatch(master.setPlantType(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: AREA.GET_PLANT_TYPE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getListReadyPlantingSeasonConfirmation = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: AREA.GET_READY_PLANTING_SEASON_CONFIRMATION
      })
    )
    setEmptyPageDataActivity(dispatch)
    try {
      let res = await requestGet(AREA_URL.GET_LIST_READY_PLANTING_SEASON_CONFIRMATION + param)
      dispatch(
        area.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: AREA.GET_READY_PLANTING_SEASON_CONFIRMATION
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const bulkConfirmationPlantingSeason = (body) => {
  return async (dispatch) => {
    general.setLoadingGlobal({
      loading: true,
      actionInitType: AREA.BULK_CONFIRMATION_PLANTING_SEASON
    })
    try {
      let res = await requestPost(AREA_URL.BULK_CONFIRMATION_PLANTING_SEASON(), body)
      dispatch(area.setResponsePost(res?.data))
      general.setLoadingGlobal({
        loading: true,
        actionInitType: AREA.BULK_CONFIRMATION_PLANTING_SEASON
      })
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: AREA.BULK_CONFIRMATION_PLANTING_SEASON
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
