/* eslint-disable no-unused-vars */
import { FormikProvider } from "formik"
import React from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import OverviewArea from "../components/OverviewArea"
import SelectedAreaList from "../components/SelectedAreaList"
import DocumentUpload from "../components/DocumentUpload"

const Overview = ({
  activeStep,
  setActiveStep,
  dataInit,
  formik,
  setModalConfirmation,
  fileAttachments,
  setFileAttachments
}) => {
  const { t } = useTranslation()

  return (
    <FormikProvider value={formik}>
      <div className={"d-flex justify-content-center w-full py-6"}>
        <div className={"w-80vw"}>
          <OverviewArea dataInit={dataInit} />
          <SelectedAreaList formik={formik} />
          <DocumentUpload
            fileAttachments={fileAttachments}
            setFileAttachments={setFileAttachments}
          />
          <div className={"d-flex justify-content-end align-items-center"}>
            <Button
              variant={"secondary"}
              onClick={() => setActiveStep(activeStep - 1)}
              className={"d-flex align-items-center color-primary mr-8"}>
              {t("button.previous")}
            </Button>
            <Button
              variant={"primary"}
              onClick={() => {
                setModalConfirmation(true)
              }}
              disabled={!formik.values.document_url}
              key={formik.values.document_url}
              className={"d-flex align-items-center color-primary"}>
              {t("button.submit")}
            </Button>
          </div>
        </div>
      </div>
    </FormikProvider>
  )
}

export default Overview
